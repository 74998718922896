import {useEffect, useState} from "react";

export default function useAvailableAiTools() {
    const [availableTools, setAvailableTools] = useState([]);

    useEffect(() => {
        fetch('/api/designs/ai-tools').then(resp => {
            if (resp.ok) {
                resp.json().then(
                    tools => setAvailableTools(tools.aiTools)
                )
            }
        })
    }, []);

    return availableTools;
}
