import "./AiToolsInUse.scss";
import {SharedContext} from "../index";
import React, {useContext, useEffect, useState} from "react";
import AiToolsForm from "./AiToolsForm";
import {Bounce, toast, ToastContainer} from "react-toastify";
export default function AiToolsInUse({designId}) {

    designId = parseInt(designId);
    const {setModal, closeModal} = useContext(SharedContext);
    const [tools, setTools] = useState([]);

    function saveTools(tools) {
        fetch(`/api/designs/${designId}/ai-tools-used`, { method: 'PUT', body: JSON.stringify(tools)})
            .then(resp => {
                if (!resp.ok) {
                    toast.error(resp.status===404 ? 'Nie odnaleziono projektu.' : `Błąd ${resp.status} ${resp.statusText}`)
                }
            })
            .catch(e => {
                toast.error(e.message)
            });
        setTools(tools);
        closeModal();
    }

    useEffect( () => {
        if (designId) {
            async function fetchAiToolsUsed() {
                const result = await fetch(`/api/designs/${designId}/ai-tools-used`);
                if (result.ok) {
                    const data = await result.json();
                    setTools(data.designAiSupport);
                }
            }
            fetchAiToolsUsed();
        }
    }, [designId])

    useEffect(() => {
        document.dispatchEvent(new CustomEvent('aiToolsInUse', {detail: { tools: tools }}))
    }, [tools]);
    

    let toolNames = [... new Set(tools.map(t => t.tool.name))].join(', ');

    return <><div className='ai-tools-in-use statusText' onClick={ () => {setModal(<AiToolsForm tools={tools} close={ closeModal } save={ saveTools }/>)} }>
        { tools.length ? <span className='ai-tool'>{ toolNames }</span> : '—'}
    </div>
    <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}/>
    </>
}
