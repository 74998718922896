import "./TitleWithIcon.scss";
export default function TitleWithIcon({title, icon}) {
    return <div className="titleWithIcon">
        <div className="icon" data-icon={icon}>
            <img src={`/ui/icons-black/${icon}.svg`} alt=""/>
        </div>
        <div className="label">
            { title }
        </div>
    </div>
}
