import useLoggedInUser from "../hooks/useLoggedInUser";
import TitleWithIcon from "./TitleWithIcon";
import React, {useEffect, useState} from "react";
import "./AiToolsForm.scss";
import useAvailableAiTools from "../hooks/useAvailableAiTools";

export default function AiToolsForm({tools: initialTools, close, save}) {
    const loggedInUser = useLoggedInUser();
    const availableTools = useAvailableAiTools();
    const [tools, setTools] = useState(initialTools);

    function addMeToTool(tool) {
        setTools([...tools, {
            designId: null,
            person: {
                id: loggedInUser.id,
                name: loggedInUser.fullName,
                avatar: loggedInUser.avatar
            },
            tool: tool
        }]);
    }

    function removeUserFromTool(entity) {
        setTools( tools.filter( t => t.person.id !== loggedInUser.id || t.tool.id !== entity.tool.id ) );
    }

    function handleSave() {
        save(tools);
    }
    function usersOfTool(theTool) {
        return <div className="users">
            {
                tools?.filter(t => t.tool.id === theTool.id).map(tool => (
                    <div className="user-widget">
                        <div className="avatar-wrapper">
                            <img src={ tool.person?.avatar } alt={ tool.person?.name }/>
                        </div>
                        <div className="name">{ tool.person?.name }</div>
                        { loggedInUser?.id === tool.person?.id ? (
                            <a onClick={() => removeUserFromTool(tool) } className="delete"
                               data-tooltip="Usuń tą informację"
                            >
                                <i className="fa fa-times-circle"></i>
                            </a>
                        ) : ''}
                    </div>
                ))
            }
            { loggedInUser && !tools?.some(t => t.person.id === loggedInUser.id && t.tool.id === theTool.id)
                ? <a className="subscribe-button" onClick={() => addMeToTool(theTool)}><i
                    className="fa fa-plus-circle"
                ></i> <span>dołącz</span></a>
                : ''
            }
        </div>
    }

    return <div className="modal-popup ai-tools-form">
        <TitleWithIcon title="Wykorzystanie narzędzi AI w projekcie" icon="artificial-intelligence"/>
        <div className="content container">
            {
                availableTools.map(aiTool => (
                    <div className="ai-tools-group">
                        <div className="label">{ aiTool.name }</div>
                        { usersOfTool(aiTool) }
                    </div>
                ))
            }
        </div>

        <div className="footer">
            <div className="negative button" onClick={close}>
                ANULUJ
            </div>

            <div className="positive button" onClick={handleSave}>
                ZAPISZ
            </div>
        </div>
    </div>
}
